<script setup>
import {
  JContentCarousel,
  JPertaciImage,
  JImageSceleton,
  JContentCarouselSkeleton,
  JFilmStrip,
} from "media-flow-ui"

import { toRefs, ref } from "vue"
import { storeToRefs } from "pinia"

import { usePageBuilder } from "~/stores/page-builder"

import Card from "~~/models/layout/components/content/Card"
import TabData from "~~/models/layout/tabs/TabData"
import { $$t } from "~~/utils/i19m"
import Palette from "~/models/palette/Palette"
import { useUrlBuilder } from "~/stores/url-builder"

const urlBuilder = useUrlBuilder()
const { getProjectId } = storeToRefs(urlBuilder)

// useNuxtApp().isHydrating = true;
// const done = useNuxtApp().deferHydration();

const pageBuilderStore = usePageBuilder()
const { deviceType } = storeToRefs(pageBuilderStore)

const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: false,
  },
  load: {
    type: Boolean,
    default: true,
    required: false,
  },
  rowOrder: {
    type: Number,
    required: true,
  },
})

const isLoading = ref(true)
const widgetData = ref([])
const { data, load } = toRefs(props)
const numberOfColumns = ref(6)
const mediaNumber = ref(0)
const blurColor = ref(null)
const showSceleton = ref(true)

const defaultSort = ref(null)

const linkData = ref(data.value.props?.linkData || data.value.linkData)

//TODO temp solution to be handled on api
const displayOnlyLiveVideosTemp = ref(
  data.value.props?.mediaPropertyFilters?.toString() === [1].toString()
)

//to change response
if (data.value?.columnsNumber) {
  numberOfColumns.value = data.value.columnsNumber
} else if (data.value.props?.columnsNumber) {
  numberOfColumns.value = data.value.props.columnsNumber
}

const dataa = ref(props.data)

if (dataa.value.style?.blurColor) {
  const palette = new Palette()
  palette.checkColor(dataa.value.style.blurColor)
  blurColor.value = palette.color
}

// const data = computed(() => props.data)

if (data.value.props?.query?.id) {
  const tabData = new TabData()
  //set layout data foreach tab static ore tab dynamic(tabs)
  tabData.setTabLayout(data.value)

  dataa.value = tabData
}

// const hasPagination = dataa.value.props.infiniteScroll;

const getImageByAspectRatio =
  dataa.value?.cardStyle && dataa.value?.cardStyle[deviceType.value]
    ? dataa.value?.cardStyle[deviceType.value].imageStyle?.aspectRatioProperty
    : null

const isStatic = dataa.value?.cardStyle?.[deviceType.value]?.isStatic
const isFilmstrip = dataa.value?.cardStyle?.[deviceType.value]?.isFilmstrip

const skip = ref(0)
const take = ref(dataa.value.props?.mediaNumber || 30) //ref(30); //
const total = ref(null)

mediaNumber.value =
  data.value?.props?.mediaNumber > 30 ? 30 : data.value?.props?.mediaNumber
numberOfColumns.value = data.value.props.columnsNumber

const queryId = dataa.value.props.queryId
const contentId = dataa.value.contentId
const sortingMethod = ref(data.value.props?.sortingMethod ?? null)
const sortingMethods = ref(data.value.props?.sortingMethods ?? [])
const parentGroupId = ref(null)

sortingMethods.value = sortingMethods.value.map((method) => {
  method.name = $$t(method.name)
  return method
})

await useAsyncData(async () => {
  // const parentGroupId = data.value?.props.card?.parentGroupId || null;
  parentGroupId.value =
    data.value?.props.card?.cards?.[deviceType.value].props?.parentGroupId ||
    data.value?.props.card?.parentGroupId ||
    null

  const params = {
    take: take.value,
    skip: skip.value,
  }

  if (sortingMethod.value) {
    defaultSort.value = sortingMethod.value
    params.sort = sortingMethod.value
    params.defaultSort = defaultSort.value
  }

  let response

  try {
    const res = await pageBuilderStore.actionGetQueryDataInitClient(
      queryId,
      params,
      contentId,
      null,
      props.rowOrder
    )
    if (res) {
      response = res.response
    }

    // total.value = response?.total;
    // skip.value += take.value;
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }

  if (response && response !== -1) {
    // showSceleton.value = false
  }

  widgetData.value = response.data.map((rawItem) => {
    const item = new Card()

    try {
      item.fromQuery(
        { ...rawItem, parentGroupId: parentGroupId.value },
        getImageByAspectRatio,
        isStatic
      )
    } catch (error) {
      console.error("out", error)
    }

    return item
  })

  if (displayOnlyLiveVideosTemp.value) {
    widgetData.value = widgetData.value.filter((item) => {
      return item.isLive === true
    })
  }

  // return widgetData.value
})

const loadDataBySort = async (sort) => {
  sortingMethod.value = sort
  skip.value = 0
  isLoading.value = true

  let response = await fetchData()

  if (!response || response.length <= 0) return

  widgetData.value = response.data.map((rawItem) => {
    const item = new Card()
    item.fromQuery(
      { ...rawItem, parentGroupId: parentGroupId.value },
      getImageByAspectRatio,
      isStatic
    )
    return item
  })
}

const fetchData = async () => {
  const params = {
    take: take.value,
    skip: skip.value,
  }

  if (sortingMethod.value) {
    params.sort = sortingMethod.value

    if (defaultSort.value) {
      params.defaultSort = defaultSort.value
    }
  }

  let response

  try {
    response = await pageBuilderStore.actionGetQueryDataFromClient(
      queryId,
      params,
      contentId,
      null,
      props.rowOrder
    )

    if (response) {
      skip.value += take.value
      total.value = response?.total
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
    return response
  }
}

onMounted(() => {
  showSceleton.value = false
})

//!grid-cols-3
//!grid-cols-4
//!grid-cols-5
//!grid-cols-6
//!grid-cols-7
//!grid-cols-8
//!grid-cols-9

const demoCard = {
  title:
    "Pexels Videos 1321208.mp4 - Pexels Videos 1321208.mp4 - Pexels Videos 1321208.mp4 - Pexels Videos 1321208.mp4 - Pexels Videos 1321208.mp4 - Pexels",
  timeDuration: "0:06",
  author: "",
  startDate: "12/06/2023",
  img: "https://cdn.vpplayer.tech/agmipnzu/encode/vjsnmhyb/thumbnails/retina.jpg?q=80",
  url: "pexels-videos-1321208",
  privacyStatusId: 3,
  projectId: "JkvEjBQEXV6",
  filmstrip:
    "https://cdn.vpplayer.tech/agmipnzu/encode/vjsnmhyb/thumbnails/filmstrip0.jpg",
}
const demoCarousel = Array.from(
  { length: numberOfColumns.value * 3 },
  () => demoCard
)
</script>

<template>
  <div class="tab-wraps-grid j-content-carousel-wrapper">
    <!-- 
    <pre class="text-red-600">
      meni-  {{ data.props.mediaPropertyFilters }}
    </pre> -->

    <JContentCarouselSkeleton
      v-if="showSceleton"
      ref="jCarousel"
      :id="dataa.id"
      :data="demoCarousel"
      :widget-identifier="dataa.contentId || dataa.id"
      :title="dataa?.title || dataa.props?.title"
      :navigation-data="linkData"
      :title-style="{ ...dataa.titleStyle, marginBottom: '20px' }"
      :blur-color="blurColor"
      :number-of-columns="numberOfColumns"
      :content-wrapper-style="{
        ...dataa?.style,
        gap: dataa.style?.gap,
        blurColor,
      }"
      :card-style="dataa.cardStyle ? dataa.cardStyle[deviceType] : {}"
      :selected-filter="sortingMethod"
      :filter-options="sortingMethods ?? []"
      :filter-content-callback="loadDataBySort"
      :sort-by-text="$$t('component_dropdown_sort_by_text')"
      :general-style="{
        wrapperStyle: dataa.style,
      }"
      :icons="{
        style: data.generalStyle?.style?.iconSettings,
      }"
      :breakpoint="deviceType"
    >
    </JContentCarouselSkeleton>
    <ClientOnly>
      <JContentCarousel
        v-if="widgetData.length > 0 && !showSceleton"
        :data="widgetData"
        :key="dataa.id"
        :widget-identifier="dataa.contentId || dataa.id"
        :title="dataa?.title || dataa.props?.title"
        :navigation-data="linkData"
        :title-style="{ ...dataa.titleStyle, marginBottom: '20px' }"
        :blur-color="blurColor"
        :number-of-columns="numberOfColumns"
        :content-wrapper-style="{ gap: dataa.style?.gap, blurColor }"
        :card-style="dataa.cardStyle ? dataa.cardStyle[deviceType] : {}"
        :selected-filter="sortingMethod"
        :filter-options="sortingMethods ?? []"
        :filter-content-callback="loadDataBySort"
        :sort-by-text="$$t('component_dropdown_sort_by_text')"
        :show-button="dataa?.props?.button"
        :general-style="{
          wrapperStyle: dataa.style,
        }"
        :icons="{
          style: data.generalStyle?.style?.iconSettings,
        }"
        :breakpoint="deviceType"
        :new-item-text="$$t('component_card_new_text')"
      >
        <!-- :sortByText= -->
        <template #button>
          <Button
            v-if="data?.components?.button"
            :data="data.components.button"
            class="z-10"
            text-before-button
          />
        </template>

        <template #card-image="{ image, style, filmstrip, alt, index }">
          <JPertaciImage
            v-if="!isLoading"
            :imageSrc="image"
            :no-lazy-image="index < 2"
            :alt="alt"
            class="w-full"
            :style="style"
          />
          <JImageSceleton v-else :style="style"></JImageSceleton>

          <JFilmStrip
            v-if="isFilmstrip"
            :src="filmstrip"
            :switch-time="0.5"
            :style="{ ...style, backgroundColor: 'unset' }"
          />
        </template>
      </JContentCarousel>
    </ClientOnly>
  </div>
</template>

<style lang="scss">
.j-page-menu.relative {
  width: 100%;
}
</style>
